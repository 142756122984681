const projects = [
  {
    title: "Amazon Consulting",
    description:
      "Static website created for a freelance client using Gatsby JS and Bootstrap. The site allows users to learn about the various services provided by the company, as well as reach out to them via a contact form.",
    repo: "https://github.com/JEmnetu/HabteJ-shop",
    deploy: "https://amazonconsultingus.com/",
    img: "/images/amazon.png",
  },
  {
    title: "Open Beauty",
    description:
      "React based application that provides users the ability to learn about different types of cosmetic health services, as well as create a platform where users can connect with cosmetic health providers. Developed alongside a team of developers and designers.",
    repo: "https://github.com/JEmnetu/HabteJ-shop",
    deploy: "https://www.openbeauty.co/",
    img: "/images/ob.png",
  },
  {
    title: "Covid-19 Dashboard",
    description:
      "Front End React application that allows users to view Covid-19 case data for countries around the world, on a sleek and responsive UI. Powered by data from the Disease.sh API on Covid-19.",
    repo: "https://github.com/JEmnetu/covid-tracker",
    deploy: "https://habtej-covid-tracker.netlify.app/",
    img: "/images/cov.png",
  },
  // {
  //   title: "HabteJ Shop",
  //   description:
  //     "Full Stack E-Commerce web application built with React, Redux, Express, MongoDB and Node JS. Users can create an account, view an inventory of items, and go through the steps to place an order.",
  //   repo: "https://github.com/JEmnetu/HabteJ-shop",
  //   deploy: "https://habtejshop.herokuapp.com/",
  //   img: "/images/shop.png",
  // },
  {
    title: "Habte-Eats",
    description:
      "Simple restaurant website that can be used as a template for a small business restaurant. Created using Gatsby JS / React.",
    repo: "https://github.com/JEmnetu/HabteEats",
    deploy: "https://habte-eats.netlify.app/",
    img: "/images/he.png",
  },
  // {
  //   title: "Off The Menu",
  //   description:
  //     "Full Stack React application that allows users with food allergies to search local restaurant menus for foods that are safe for them to eat. The application uses a Mongo + Express backend with a React frontend.",
  //   repo: "https://gitlab.com/off-the-menu/site",
  //   deploy: "https://otm-online.firebaseapp.com/Login",
  //   img: "/images/otm.png",
  // },
  // {
  //   title: "Todo Application",
  //   description:
  //     "Front End todo application created with React JS. This application allows the user to enter a list of todos in order to better organize their daily tasks.",
  //   repo: "https://github.com/JEmnetu/todo-app",
  //   deploy: "https://jeh-todo.netlify.app/",
  //   img: "/images/todo.png",
  // },
];

export default projects;
